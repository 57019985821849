import { ClassNames } from "@emotion/react";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@material-ui/core";

import { ControlledFormFieldProps } from "@rewards-web/shared/types";

import { useFormControlContext } from "../form/form-control";
import { Typography } from "../typography";

export interface CheckboxFieldProps extends ControlledFormFieldProps {
  label?: React.ReactNode;
  display?: "block" | "inline";
  disabled?: boolean;
  fontWeight?: number;
}

export function CheckboxField({
  display = "inline",
  label,
  value,
  onChange,
  disabled = false,
  fontWeight = 800,
}: CheckboxFieldProps) {
  const { submitting } = useFormControlContext();

  return (
    <ClassNames>
      {({ css }) => (
        <FormControlLabel
          classes={{
            label: css`
              font-weight: ${fontWeight};
            `,
          }}
          className={css`
            display: ${display === "block" ? "flex" : "inline-flex"};
          `}
          label={<Typography>{label}</Typography>}
          control={
            <MuiCheckbox
              color="primary"
              disabled={disabled || submitting}
              checked={value !== undefined ? !!value : undefined}
              onChange={
                onChange ? (_, checked) => onChange(checked) : undefined
              }
              className={css`
                padding: 6px;
              `}
            />
          }
        />
      )}
    </ClassNames>
  );
}
